@import "../../../globalStyle/colours.scss";

.pageContainerHome {
  display: flex;
  justify-content: flex-start;
  padding: 0 5vw;
  width: 100%;
  height: 100%;
  max-width: calc(100% - 10vw);
}

.homeContactBtn {
  color: $base-hover-color;
  cursor: pointer;
  font-size: 13px;
  letter-spacing: 4px;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid $base-hover-color;
  margin-top: 25px;
  width: 150px;
  text-align: center;
  font-weight: 500;
  background-color: transparent;
  z-index: 9;

  &:hover {
    background-color: $base-hover-color;
    color: $base-background-color;
    border-color: $base-hover-color;
  }
}

@media only screen and (max-width: 900px) {
  .pageContainerHome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;
  }

  .contactBtn {
    margin-top: 25px;
  }
}

.timLogo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 1;
  color: $base-hover-color;
  flex-direction: column;
  width: 40%;
  padding-right: 50px;

  img {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .timLogo {
    width: 35%;
  }
}

@media only screen and (max-width: 900px) {
  .timLogo {
    display: none;
  }
}

.photoQuote {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .photoQuote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: flex-start;
    padding: 0;
  }
}

@media only screen and (max-width: 900px) {
  .animatedQuotes {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    margin: 1em 0;
  }
}

.allHeadingsBtn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  a {
    display: flex;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 600px) {
  .allHeadingsBtn {
    width: 100%;

    button {
      width: 100%;
    }
  }
}

.subHeading {
  width: 100%;
  display: flex;
  justify-content: left;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 3px;
  color: $secondary-font-color;
  font-weight: 450;
  font-size: 2vw;
  margin: 0;
  padding-top: 1vh;
  z-index: 9;
}

@media only screen and (max-width: 1100px) {
  .subHeading {
    padding-top: 0;
    font-size: 2.5vw;
  }
}

:global(.x1) {
  color: "white";
  font-size: 8vw;
}
