@import "../../../globalStyle/colours.scss";

.projCard {
  margin-right: 5%;
  max-width: 100%;
  min-width: 5%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  align-self: flex-end;
  justify-content: center;
  position: relative;

  .projCardImg {
    align-self: flex-end;
    display: flex;
    max-width: 100%;
    min-width: 5%;
    transition: opacity 0.3s ease-in-out;
    transition: filter 0.3s ease-in-out;
    border-radius: 15px;
  }

  .scrollCards {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    color: $base-font-color;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    .companyInfo {
      text-align: center;
      text-shadow: 0 1px 0 black;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h4 {
        flex-wrap: wrap;
      }
    }

    .projectInfo {
      text-align: center;
      text-shadow: 0 1px 0 black;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    h4 {
      flex-wrap: wrap;
    }
    p {
      flex-wrap: wrap;
    }
  }

  &:hover {
    .projCardImg {
      opacity: 0.2;
      filter: drop-shadow(0 0 0.75rem $secondary-font-color);
    }
    .scrollCards {
      opacity: 1;
    }
  }
}

.cardLink {
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
  min-width: 5%;
  width: 75%;
}

.mobileCard {
  display: flex;
  justify-content: flex-end;
  max-width: 499px;
  width: 75%;
  min-width: 5%;
  margin-right: 15%;
}

@media only screen and (max-width: 1200px) {
  .projCard {
    justify-content: flex-end;
  }

  .cardLink {
    width: 80%;
  }

  .mobileCard {
    display: flex;
    justify-content: flex-end;
    margin-right: 5%;
  }
}

@media only screen and (max-width: 900px) {
  .mobileCard {
    width: 90%;
    .projCard {
      margin-right: 0;
    }
  }

  .cardLink {
    width: 85%;
  }

  .projCard {
    margin-right: 5%;
    width: 90%;

    .scrollCards {
      h4 {
        font-size: 14px;
        margin: 5px 0;
      }
      p {
        font-size: 12px;
        margin: 5px 0;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .mobileCard {
    width: 85%;
    .projCard {
      margin-right: 0;
    }
  }
  .projCard {
    margin-right: 5%;

    .scrollCards {
      h4 {
        font-size: 10px;
        margin: 2.5px 0;
      }
      p {
        font-size: 8px;
        margin: 2.5px 0;
      }
    }
  }
}

@media only screen and (max-width: 300px) {
  .projCard {
    .scrollCards {
      h4 {
        font-size: 8px;
        margin: 2px 0;
      }
      p {
        font-size: 6px;
        margin: 2px 0;
      }
    }
  }
}
